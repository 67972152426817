<template>
  <v-layout wrap class="chips-wrap">
    <v-row>
      <div v-if="filters.channel && filters.channel.id" class="chip-row">
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-chip
              v-on="on"
              class="custom-chip gender-chip"
              close
              outlined
               close-icon="fa fa-times"
              text-color="teal"
              color="teal"
              @click:close="removeChip('channel', filters.channel.id)"
            >
              <v-icon class="mr-1 custom-chip-icon" color="teal">fab fa-{{ filters.channel.id }}</v-icon>

              {{ filters.channel.text }}
            </v-chip>
          </template>
          <span>Influencer channel</span>
        </v-tooltip>
      </div>
      <div v-if="filters.gender && filters.gender.id" class="chip-row">
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-chip
              v-on="on"
              class="custom-chip gender-chip"
              close
              outlined
               close-icon="fa fa-times"
              text-color="teal"
              color="teal"
              @click:close="removeChip('gender', filters.gender.id)"
            >
              <v-icon class="mr-1 custom-chip-icon" color="teal">fal fa-user</v-icon>

              {{ filters.gender.text }}
            </v-chip>
          </template>
          <span>Influencer gender</span>
        </v-tooltip>
      </div>
      <div
        v-for="category_id in filters.category_ids"
        :key="category_id"
        class="chip-row"
      >
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-chip
              v-on="on"
              class="custom-chip gender-chip"
              close
              outlined
               close-icon="fa fa-times"

              text-color="teal"
              color="teal"
              @click:close="removeChip('category_ids', category_id)"
            >
              <v-icon class="mr-1 custom-chip-icon">fal fa-user</v-icon>
              {{ getCategoryTextById(category_id)}}
            </v-chip>
          </template>
          <span>Influencer category</span>
        </v-tooltip>
      </div>
      <div v-for="age_id in filters.age_ranges" :key="age_id" class="chip-row">
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-chip
              v-on="on"
              class="custom-chip gender-chip"
              close
              outlined
              text-color="teal"
              close-icon="fa fa-times"
              color="teal"
              @click:close="removeChip('age_ranges', age_id)"
            >
              <v-icon class="mr-1 custom-chip-icon">fal fa-user</v-icon>

              Infl. age {{ getAgeTextById(age_id) }}
            </v-chip>
          </template>
          <span>Influencer age</span>
        </v-tooltip>
      </div>
      <div v-if="filters.max_followers || filters.min_followers" class="chip-row">
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-chip
              v-on="on"
              class="custom-chip gender-chip"
              close
              outlined
              text-color="teal"
               close-icon="fa fa-times"

              color="teal"
              @click:close="removeChip('followers')"
            >
              <v-icon class="mr-1 custom-chip-icon">fal fa-user</v-icon>
              <span class="mr-1">Followers</span>
              <span v-if="filters.min_followers || filters.min_followers === 0">
                {{ filters.min_followers.toLocaleString() }}
              </span>
              <span class="mx-1"> - </span>
              <span v-if="filters.max_followers">
                {{ filters.max_followers.toLocaleString() }}
              </span>
              <span v-else>100 000</span>
            </v-chip>
          </template>
          <span>Influencer followers</span>
        </v-tooltip>
      </div>
      <div
        v-for="item in filters.age_genders"
        :key="item.id"
        class="chip-row"
      >
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-chip
              v-on="on"
              class="custom-chip advanced-chip"
              close
              outlined
              text-color="secondary"
               close-icon="fa fa-times"

              color="secondary"
              @click:close="removeChip('age_genders', item.id)"
            >
              <v-icon class="mr-1 advanced-chip-icon">fal fa-users</v-icon>

              {{ item.name }}
            </v-chip>
          </template>
          <span>Follower age and gender</span>
        </v-tooltip>
      </div>
      <div v-for="item in filters.cities" :key="item.id" class="chip-row">
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-chip
              v-on="on"
              class="custom-chip advanced-chip"
              close
              outlined
               close-icon="fa fa-times"
              text-color="secondary"
              color="secondary"
              @click:close="removeChip('cities', item.id)"
            >
              <v-icon class="mr-1 advanced-chip-icon">fal fa-users</v-icon>

              {{ item.city_name }}
            </v-chip>
          </template>
          <span>Follower location</span>
        </v-tooltip>
      </div>
    </v-row>
  </v-layout>
</template>
<script>
export default {
  props: {
    filters: {
      required: true,
      type: Object
    },
    categories: {
      require: true,
      type: Array
    },
    ages: {
      require: true,
      type: Array
    }
  },
  methods: {
    removeChip(item, id) {
      this.$emit("remove", {item, id});
    },
    getCategoryTextById(id) {
      const item = this.categories.find(item => {
        return Number(item.value) === Number(id);
      });
      return item ? item.text : "";
    },
    getAgeTextById(id) {
      const item = this.ages.find(item => {
        return item.value.toString() === id.toString();
      });
      return item ? item.text : "";
    }
  }
};
</script>
<style lang="scss" scoped>
.chip-row{
  margin-bottom: 13px;
}

.chips-wrap{
  max-width: 70%;
  margin-left: 15px;
  @media only screen and (max-width: 600px) {
      margin-left: 11px;
  }

  .row {
    margin-top: 15px;
  }
}
</style>